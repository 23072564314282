import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Grand Horizon Retreat
			</title>
			<meta name={"description"} content={"Вирушайте у виняткову подорож, де розкіш зустрічається з комфортом у серці вічної елегантності. У Grand Horizon Retreat ми пишаємося тим, що пропонуємо справді незабутні враження, адаптовані до бажань кожного гостя."} />
			<meta property={"og:title"} content={"Grand Horizon Retreat"} />
			<meta property={"og:description"} content={"Вирушайте у виняткову подорож, де розкіш зустрічається з комфортом у серці вічної елегантності. У Grand Horizon Retreat ми пишаємося тим, що пропонуємо справді незабутні враження, адаптовані до бажань кожного гостя."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-3.jpg?v=2024-05-24T13:59:02.936Z"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header>
		<Section
			padding="90px 0 120px 0"
			lg-margin="0 0 0 0"
			lg-padding="80px 0 80px 0"
			background="#ffffff"
			quarkly-title="Price-20"
		>
			<Text margin="0px 0px 20px 0px" font="--lead" text-align="center">
				ПРАЙС ЛИСТ
			</Text>
			<Text
				font="--headline1"
				color="--darkL1"
				lg-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 10px 0px"
				margin="0px 0 4rem 0"
				width="100%"
				md-width="100%"
				md-margin="0 0 20px 0"
				text-align="center"
			>
				Послуги
			</Text>
			<Box padding="0px 120px 0px 120px" lg-flex-direction="column" lg-padding="0px 0 0px 0">
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Номери з видом на океан
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							₴5,000 за ніч
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Люкси в саду
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							₴4,500 за ніч
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Ексклюзивні Вілли
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							₴10,000 за ніч
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Тераса
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							середній рахунок ₴1,500
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Гурманський Лаунж
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							середній рахунок ₴2,000
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Обслуговування номерів
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							₴500
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Терапевтичні Масажі
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							₴1,000 за годину
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Детокс Програми
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							₴3,000 за день
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Фітнес Центр
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							₴500
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Екскурсії з гідом
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							₴2,000
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					display="flex"
					justify-content="center"
					margin="0px 0px 40px 0px"
					md-flex-direction="column"
				>
					<Text
						font="--headline3"
						color="--darkL1"
						lg-margin="0px 0px 20px 0px"
						sm-margin="0px 0px 10px 0px"
						margin="0px 0 25px 0"
						width="45%"
						md-width="100%"
						md-margin="0 0 20px 0"
					>
						Дитячий Клуб
					</Text>
					<Box
						min-width="100px"
						width="15%"
						display="flex"
						flex-direction="column"
						align-items="flex-end"
						md-width="100%"
						md-align-items="flex-start"
					>
						<Text margin="0px 0px 3px 0px" font="--headline3" text-align="left">
							₴1,500{" "}
						</Text>
					</Box>
				</Box>
			</Box>
			<Text margin="0px 0px 20px 0px" font="--headline3" text-align="center">
				Щоб дізнатися більше про наші ексклюзивні послуги або налаштувати ваше майбутнє перебування, будь ласка, зв'яжіться з нашою командою консьєржів. Наш персонал присвячений створенню перебування, яке перевершить ваші очікування, забезпечуючи ретельне управління кожною деталлю для вашого максимального комфорту та задоволення.
			</Text>
		</Section>
		<Section
			padding="60px 0"
			sm-padding="40px 0"
			min-height="300px"
			sm-min-height="auto"
			background="--color-light"
			display="flex"
			flex-direction="column"
		>
			<Box
				min-width="100px"
				min-height="100px"
				width="100%"
				lg-width="100%"
				display="flex"
				flex-direction="row"
				justify-content="center"
				lg-margin="0px 0px 50px 0px"
				margin="3rem 0px 0px 0px"
				max-height="400px"
			>
				<Image
					src="https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/2-1.jpg?v=2024-05-24T13:59:02.927Z"
					display="block"
					object-fit="contain"
					lg-width="100%"
					lg-height="700px"
					sm-height="300px"
					margin="0px 5px 0px 0px"
					md-height="500px"
					srcSet="https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-1.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-1.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-1.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-1.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-1.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-1.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/2-1.jpg?v=2024-05-24T13%3A59%3A02.927Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Image
					src="https://uploads.quarkly.io/665070d58e2e8e00217c1022/images/3-4%20%281%29.jpg?v=2024-05-24T13:59:02.939Z"
					display="block"
					object-fit="contain"
					lg-width="100%"
					lg-height="700px"
					sm-height="300px"
					margin="0px 0px 0px 5px"
					md-height="500px"
					srcSet="https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-4%20%281%29.jpg?v=2024-05-24T13%3A59%3A02.939Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-4%20%281%29.jpg?v=2024-05-24T13%3A59%3A02.939Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-4%20%281%29.jpg?v=2024-05-24T13%3A59%3A02.939Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-4%20%281%29.jpg?v=2024-05-24T13%3A59%3A02.939Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-4%20%281%29.jpg?v=2024-05-24T13%3A59%3A02.939Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-4%20%281%29.jpg?v=2024-05-24T13%3A59%3A02.939Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665070d58e2e8e00217c1022/images/3-4%20%281%29.jpg?v=2024-05-24T13%3A59%3A02.939Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});